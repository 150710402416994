import React, { useContext, useEffect } from "react"
import UserContext from "../components/UserContext"
import Home_7 from "./home/home_7"
import { NextSeo } from 'next-seo'
import Config from '../site.config'

export default function Home() {
  const { scrollRef } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  return (
    <div>
        <NextSeo
        title="Romikya Labs"
        description={Config.site.description}
        openGraph={{
          url: Config.site.URL,
          title: Config.site.title,
          description: Config.site.description,
          images: [
            {
              url: Config.site.preview,
              width: 424,
              height: 392,
              alt: 'banner',
              type: 'image/png',
            },
          ],
          site_name: 'Romikya Labs',
        }}
      />
      <Home_7 />
    </div>
  );
}
